import { Splide, SplideSlide } from "@splidejs/react-splide";
import meta from "../../static/Icons/meta.png";
import apple from "../../static/Icons/apple.png";
import amazon from "../../static/Icons/amazon.png";
import google from "../../static/Icons/google.png";
import netflix from "../../static/Icons/netflix.png";
import boy from "../../static/Icons/boy.png";
import React from "react";
import ResponsiveWrapper from "../../utils/ResponsiveWrapper";

const MentorsStrip = () => {
  return (
    <ResponsiveWrapper>
      <div className="mt-16 flex flex-col items-center justify-center mb:px-2">
        <div className=" flex items-center">
          <img alt="" className="w-[130px]" src={boy} />
          <p className="text-4xl font-semibold md:text-3xl">
            Our Mentors are from
          </p>
        </div>
        <div className="flex justify-between space-x-10 border bg-white py-9 px-10 w-full rounded-3xl shadow-lg md:grid md:grid-cols-2 md:gap-3 mb:hidden">
          {imgData.map((item, index) => (
            <Image key={index} src={item} />
          ))}
        </div>
        <div className="hidden mb:block bg-white py-9 px-10 border mx-10 w-full rounded-xl">
          <Splide
            options={{
              perPage: 1,
              gap: "5rem",
              width: "100%",
              rewind: true,
              arrows: false,
              autoplay: true,
              interval: 2600,
              padding: 0,
              mediaQuery: "max",
            }}
          >
            {imgData.map((item, index) => (
              <SplideSlide>
                <Image key={index} src={item} />
              </SplideSlide>
            ))}
          </Splide>
        </div>
      </div>
    </ResponsiveWrapper>
  );
};

const imgData = [meta, amazon, apple, netflix, google];

const Image = ({ src }) => (
  <img
    className="w-auto h-[35px]"
    src={src}
    alt=""
    style={{ margin: "auto" }}
  />
);

export default MentorsStrip;
