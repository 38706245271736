import { Fade } from "@mui/material";
import React from "react";
import super30Icon1 from "../../static/Icons/Super30/Super30Icon1.png";
import super30Icon2 from "../../static/Icons/Super30/Super30Icon2.png";
import leftCoin from "../../static/Icons/leftCoin.png";
import rightCoin from "../../static/Icons/rightCoin.png";
import Hero1 from "../../static/Python/Hero1.png";
import Hero2 from "../../static/Python/Hero2.png";
import Hero3 from "../../static/Python/Hero3.png";
import Hero4 from "../../static/Python/Hero4.png";

const HeroSection = ({ title, courseDescription }) => {
  return (
    <>
      <Fade in={true} mountOnEnter unmountOnExit timeout={1200}>
        <div className="flex flex-col justify-between items-center px-[10%] xl:px-[3%] lg:px-[2%] md:px-[1%] relative py-20 font-sans z-20 sm:flex-col sm:px-[3%] md:text-sm">
          <div>
            <p className="text-5xl font-semibold mt-10 md:text-4xl mb:text-3xl text-center">
              {title}
            </p>
          </div>
          {courseDescription}
          <div className="flex space-x-8 xl:space-x-2 md:grid md:grid-cols-2 md:space-x-0 md:gap-4">
            {data1.map((item) => (
              <div key={item.icon} className="flex items-center space-x-2">
                <img
                  alt=""
                  src={item.icon}
                  className="w-[40px] h-[40px] object-contain mb:w-[30px] mb:h-[30px] animate-bounce-slow"
                />
                <p>{item.name}</p>
              </div>
            ))}
          </div>

          <img
            alt=""
            src={super30Icon1}
            className="absolute z-10"
            style={{ top: "-20%", right: "60%" }}
          />
          <img
            alt=""
            src={super30Icon2}
            className="absolute z-10"
            style={{ top: "-27%", right: "23%" }}
          />
          <img
            alt=""
            src={leftCoin}
            className="absolute z-10 sm:hidden"
            style={{ bottom: "46%", left: "0%" }}
          />
          <img
            alt=""
            src={rightCoin}
            className="absolute z-10 sm:hidden"
            style={{ bottom: "4%", right: "0%" }}
          />
        </div>
      </Fade>
    </>
  );
};

const data1 = [
  { name: "Industry Experts as Mentors", icon: Hero1 },
  { name: "Includes Certification", icon: Hero2 },
  { name: "Learn at Your Own Pace", icon: Hero3 },
  { name: "Unlimited Access Forever", icon: Hero4 },
];
export default HeroSection;
