import React from "react";
import { Link } from "react-router-dom";
import CoursesDropdown from "../components/CoursesDropdown";
import HireDropDown from "../components/HireDropDown";
import logo from "../static/Icons/logo.png";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { setLoggedIn } from "../redux/auth/authSlice";
import Profile from "./Profile";
import { isLoggedIn } from "../utils/helperFunctions";

const DesktopNavBar = ({ navLinks, isAuthorized, setOpenProfileDrawer }) => {
  const dispatch = useDispatch();
  return (
    <>
      <div className="mb:py-2">
        <Link to={"/"} className="flex items-center space-x-1">
          <img alt="logo" src={logo} className="mb:w-10 w-12 py-3 lg:w-9" />
          <p className="text-2xl lg:text-xl">
            Maangler <sub style={{ fontSize: "12px" }}>beta</sub>
          </p>
        </Link>
      </div>
      <div className="space-x-4 md:hidden flex items-center 2xl:space-x-1">
        <CoursesDropdown />
        {/* <HireDropDown /> */}
        {navLinks.map(
          (item, index) =>
            item.id !== 3 &&
            item.id !== 4 &&
            item.id !== 6 && (
              <Link to={item.link} key={index}>
                <button className="text-gray-600 hover:bg-gray-100 px-3 py-1 rounded-2xl xl:px-1 lg:text-sm">
                  {item.title}
                </button>
              </Link>
            )
        )}
        {isAuthorized ? (
          <button
            className="text-[#32A3C5] border border-[#32A3C5] px-3 py-1 rounded-2xl transition ease-in-out duration-500 hover:scale-105 hover:bg-primary hover:text-white"
            onClick={() => {
              Cookies.remove("access_token", {
                domain: ".maangler.in",
                path: "/",
              });
              dispatch(setLoggedIn(false));
              window.location.reload();
            }}
          >
            Log Out
          </button>
        ) : (
          <a href="https://lms.maangler.in/">
            <button className="text-[#32A3C5] border border-[#32A3C5] px-3 py-1 rounded-2xl transition ease-in-out duration-500 hover:scale-105 hover:bg-primary hover:text-white">
              Login
            </button>
          </a>
        )}
        {isLoggedIn() && (
          <div onClick={() => setOpenProfileDrawer((prev) => !prev)}>
            <Profile />
          </div>
        )}
      </div>
    </>
  );
};

export default DesktopNavBar;
