import React from "react";
import notepad from "../../static/BecomeMentor/Notepad.png";
import boyJumping from "../../static/BecomeMentor/boyJumping.png";
import boyInBox from "../../static/BecomeMentor/boyInBox.png";
import check from "../../static/BecomeMentor/check.png";

const JoinUsSection = () => {
  return (
    <div className="py-20 layout">
      <div className="space-y-8">
        <p className="text-3xl font-semibold text-center">
          We wish you join us <span className="text-4xl">🎉</span> <br /> If you wish the same, here
          is how to join us
        </p>
        <p className="text-center">
          We know you would be a right fit for any of these, however, before
          going to the process, do check yourself!
        </p>
        <div className="bg-[#eff8fb] p-4 max-w-[600px] mx-auto rounded-xl space-y-1">
          <p className="font-semibold text-lg">Maangler</p>
          <p className="text-gray-600">
            Software Engineering for Working Professionals
          </p>
          {points.map((item, index) => (
            <div className="flex space-x-2">
              <img src={check} alt="" className="w-4 object-contain" />
              <p className="font-semibold text-green-500" key={index}>
                {item}
              </p>
            </div>
          ))}
        </div>
        <p className="text-center text-gray-600">
          * You will also need to have a laptop/computer with a webcam and a
          high-speed internet connection
        </p>
        <div className="flex justify-center space-x-10 md:flex-wrap md:gap-10 md:space-x-0">
          {cardData.map(({ id, cardDes, cardIcon, cardTitle }) => (
            <div
              key={id}
              className="border max-w-[300px] p-6 bg-white shadow-lg rounded-xl space-y-2"
            >
              <img
                alt=""
                src={cardIcon}
                className="w-[100px] h-[100px] object-contain"
              />
              <p className="font-semibold text-lg">{cardTitle}</p>
              <p>{cardDes}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="mt-16 space-y-4">
        <p className="text-4xl font-semibold text-center">
          Become a mentor with us
        </p>
        <p className="text-center">
          Ready to make a difference? Join Maangler as a <br /> mentor and be a
          part of something great!
        </p>
        <button className="block mx-auto border-2 border-primary text-primary font-semibold py-3 px-10 rounded-full">
          Apply now
        </button>
      </div>
    </div>
  );
};

const points = [
  "You should have a minimum of 2 years of relevant industry experience.",
  "Proficient in DSA & System design.",
  "Proficient in DSA & System design.",
];

const cardData = [
  {
    id: 1,
    cardIcon: notepad,
    cardTitle: "You Apply",
    cardDes:
      "Fill out the application or reach out to us directly if you have any questions.",
  },
  {
    id: 2,
    cardIcon: boyJumping,
    cardTitle: "Your Profile is Reviewed",
    cardDes:
      "If your profile matches our criteria we will give you a call to discuss further steps.",
  },
  {
    id: 3,
    cardIcon: boyInBox,
    cardTitle: "You Mentor",
    cardDes:
      "Once enrolled in the mentorship program, log in to see a dashboard with the students.",
  },
];

export default JoinUsSection;
