import React from "react";
//import HeroSection from "../components/PythonInternshipProgram/HeroSection";
//import CourseInfo from "../components/PythonInternshipProgram/CourseInfo";
import Background from "../static/Python/Background.png";
import MentorsStrip from "../components/PythonInternshipProgram/MentorsStrip";
import ProgramRatings from "../components/PythonInternshipProgram/ProgramRatings";
import HeroSection from "../components/Courses/HeroSection";
import CourseInfo from "../components/Courses/CourseInfo";
import MentorsSections from "../components/PythonInternshipProgram/MentorsSection";

const PythonInternship = () => {
  return (
    <div
      style={{
        backgroundImage: `url(${Background})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      className="text-white pb-20 overflow-hidden"
    >
      {/* <HeroSection /> */}
      <HeroSection
        courseDescription={pythonCourseDes}
        title={"Python Internship Program"}
      />
      <ProgramRatings border={"#07D2FF"} />
      <CourseInfo
       data={data}
       border={"#076AFF"}
       courseName="Python" />
      <MentorsStrip />
      <MentorsSections />
    </div>
  );
};
const pythonCourseDes = (
  <p className="text-center mb-10 mt-8 relative z-50 mb:text-sm">
    Maangler Python is a thoughtfully curated 2-month program that kickstarts
    your Python coding journey under the guidance of top mentors from leading
    MAANG companies.
    <br className="lg:hidden" /> Guided by an extraordinary community of fellow
    students and invaluable industry connections,
    <br className="lg:hidden" /> we're here to provide unwavering support on
    your journey.
  </p>
);

const data = {
  "Month 1": [
    {
      title: "Week 1",
      des: "Introduction to Python",
      info: [
        "Introduction to Python, its applications, and setup",
        "Variables, data types, and basic operations",
        "Conditional statements",
      ],
    },
    {
      title: "Week 2",
      des: "Loops & Functions",
      info: [
        "While loops, for loops, and range()",
        "Functions, parameters, and return values",
        "Function scope",
      ],
    },
    {
      title: "Week 3",
      des: "Lists, Dictionaries, and File Handling",
      info: ["Lists, tuples, and dictionaries", "Reading and writing files"],
    },
    {
      title: "Week 4",
      des: "Object-Oriented Programming",
      info: [
        "Introduction to OOP, classes, and objects",
        "Creating classes and inheritance",
      ],
    },
  ],
  "Month 2": [
    {
      title: "Week 1",
      des: "Modules and Libraries",
      info: [
        "Modules and packages",
        "Using popular libraries (e.g., NumPy, Pandas)",
      ],
    },
    {
      title: "Week 2",
      des: "Working with Data + Matplotlib",
      info: [
        "Reading and writing CSV and JSON files",
        "Data visualization",
        "Basics of web scraping",
      ],
    },
    {
      title: "Week 3",
      des: "Project 1 (instructor-led guided project) + File Handling in Python",
      info: [
        "The skills that you have learned till now, why not try them and make a guided project??",
      ],
    },
    {
      title: "Week 4",
      des: "Unguided Project and Conclusion",
      info: [
        "Basic EDA with Dataset provided. Take a dataset and analyze it (e.g., CSV file) as input and provide insights into the data. You can implement features like data summarization, basic statistics (mean, median, mode), and data visualization (using matplotlib or seaborn).",
      ],
    },
  ],

};

export default PythonInternship;
