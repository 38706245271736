import React from "react";
import JavaProgram from "../../static/Icons/Super30/JavaProgram.png";
import PythonProgram from "../../static/Icons/Super30/PythonProgram.png";
import { Link } from "react-router-dom";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import FullStack from "../../static/Icons/Super30/FullStack.png";
import Ai from "../../static/Icons/Super30/AI.png";
import AppDev from "../../static/Icons/Super30/AppDev.png";
import ml from "../../static/Icons/Super30/ML.png";
import cpp from "../../static/Icons/Super30/C++.png";
import Cloud from "../../static/Icons/Super30/CloudComp.png";
import Cyber from "../../static/Icons/Super30/cyber.png";
import Dsa from "../../static/Icons/Super30/DSA.png";
import DataSci from "../../static/Icons/Super30/DataSci.png";

const ProgramsWeOffer = () => {
  return (
    <div className="mt-20 flex flex-col items-center font-sans sm:px-[3%]">
      <p className="text-4xl font-semibold text-center font-sans lg:text-3xl mb:text-2xl">
        Programs We Offer
      </p>
      <div className="mt-10 mb:flex-wrap mb:space-x-0 mb:gap-4 mb:justify-center container flex flex-wrap justify-center ">
        {data.map((item, index) => (
          <Card
            icon={item.icon}
            link={item.link}
            title={item.title}
            key={index}
          />
        ))}
      </div>
    </div>
  );
};

const Card = ({ icon, title, link }) => {
  return (
    <div className="mb-6 p-4">
      <Link to={link}>
        <div
          className="flex flex-col items-center font-semibold space-y-2 cursor-pointer transition ease-in-out duration-500
            hover:border hover:bg-gray-200 py-2 px-2 hover:scale-105 sm:w-[180px] mb:text-xs w-[220px] rounded-lg shadow-md border bg-gray-100 "
        >
          <div className="flex w-full">
            <img
              alt=""
              src={icon}
              className="w-[100px] h-[100px] object-contain grow ml-4"
            />
            <ArrowOutwardIcon
              className="ml-auto text-gray-500"
              style={{ width: "20px" }}
            />
          </div>
          {title}
        </div>
      </Link>
    </div>
  );
};

const data = [
  {
    icon: JavaProgram,
    title: (
      <p className="text-center">
        Java Internship <br /> Program
      </p>
    ),
    link: "/java-internship-program",
  },
  {
    icon: PythonProgram,
    title: (
      <p className="text-center">
        Python Internship <br /> Program
      </p>
    ),
    link: "/python-internship-program",
  },
  {
    icon: FullStack,
    title: (
      <p className="text-center">
        Fullstack Internship <br /> Program
      </p>
    ),
    link: "/full-stack-program",
  },
  {
    icon: Ai,
    title: (
      <p className="text-center">
        Artificial Intelligence <br /> Program
      </p>
    ),
    link: "/coming-soon",
  },
  {
    icon: ml,
    title: (
      <p className="text-center">
        Machine Learning
        <br /> Program
      </p>
    ),
    link: "/coming-soon",
  },
  {
    icon: cpp,
    title: (
      <p className="text-center">
        C++ <br /> Program
      </p>
    ),
    link: "/coming-soon",
  },
  {
    icon: DataSci,
    title: (
      <p className="text-center">
        Data Science
        <br /> Program
      </p>
    ),
    link: "/coming-soon",
  },
  {
    icon: AppDev,
    title: (
      <p className="text-center">
        App Development
        <br /> Program
      </p>
    ),
    link: "/coming-soon",
  },
  {
    icon: Cloud,
    title: (
      <p className="text-center">
        Cloud Computing <br /> Program
      </p>
    ),
    link: "/coming-soon",
  },
  {
    icon: Cyber,
    title: (
      <p className="text-center">
        Cyber Security
        <br /> Program
      </p>
    ),
    link: "/coming-soon",
  },
  {
    icon: Dsa,
    title: (
      <p className="text-center">
        DSA & System Design
        <br /> Program
      </p>
    ),
    link: "/coming-soon",
  },
];

export default ProgramsWeOffer;
