import React from "react";
import Background from "../static/fullstack/Background.jpg";
import HeroSection from "../components/Courses/HeroSection";
import ProgramRatings from "../components/PythonInternshipProgram/ProgramRatings";
import MentorsStrip from "../components/PythonInternshipProgram/MentorsStrip";
import MentorsSections from "../components/PythonInternshipProgram/MentorsSection";
import CourseInfo from "../components/Courses/CourseInfo";

const FullstackInternship = () => {
  return (
    <div
      style={{
        backgroundImage: `url(${Background})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      className="text-white pb-20 overflow-hidden"
    >
      <HeroSection
        title={"Full Stack Development"}
        courseDescription={fullStackCourseDes}
      />
      <ProgramRatings border={"#FAFF07"} />
      <CourseInfo 
        border={"#FAFF07"}
        data={data}
        courseName="Fullstack" />
      <MentorsStrip />
      <MentorsSections />
    </div>
  );
};

const data = {
  "Month 1": [
    {
      title: "Week 1-2",
      des: "Introduction to Web Development and HTML/CSS Basics",
      info: [
        "Introduction to Web Development and Web Technologies",
        "HTML5: Structure, Elements, and Semantics",
        "CSS3: Styling, Selectors, Box Model",
      ],
    },
    {
      title: "Week 3-4",
      des: "Advanced CSS and Responsive Design",
      info: [
        "CSS Layouts: Flexbox and CSS Grid",
        "Media Queries and Responsive Design Principles",
        "CSS Preprocessors (e.g., SASS)",
      ],
    },
    {
      title: "",
      des: "",
      info: [
        "Minor Project: Personal Portfolio Website. Create a personal portfolio website using HTML and CSS. Showcase your projects, skills, and a responsive design.",
        "Major Project: Landing Page with Responsive Design. Build a landing page for a fictional product or service. Apply advanced CSS techniques and ensure responsiveness across devices.",
      ],
    },
  ],
  "Month 2": [
    {
      title: "Week 5-6",
      des: "Introduction to Back-End and Node.js Basics",
      info: [
        "Introduction to Back-End Development and Server-side Concepts",
        "Setting Up Node.js Environment",
        "Building a Basic Server with Node.js and Express.js",
      ],
    },
    {
      title: "Week 7-8",
      des: "Databases and RESTful APIs",
      info: [
        "Introduction to Databases: SQL and NoSQL",
        "Working with MongoDB (or other NoSQL databases)",
        "Building RESTful APIs with Express.js",
      ],
    },
    {
      title: "",
      des: "",
      info: [
        "Minor Project: To-Do List Web App. Create a to-do list web application using Node.js, Express.js, and MongoDB. Implement CRUD operations for tasks.",
        "Major Project: Blogging Platform. Develop a blogging platform with user registration, authentication, and the ability to create, edit, and delete blog posts.",
      ],
    },
  ],
  "Month 3": [
    {
      title: "Week 9-10",
      des: "Connecting Front-End with Back-End",
      info: [
        "Building API Endpoints for Front-End Interaction",
        "Next 20 Days Major Projects.",
        "Using Templating Engines (e.g., EJS)",
      ],
    },
    {
      title: "Week 11-12",
      des: "Deployment & Advanced Concepts",
      info: [
        "Deployment Strategies: Heroku, Netlify, AWS, etc.",
        "Server-Side Rendering vs. Client-Side Rendering",
        "Introduction to Authentication and Security Practices",
      ],
    },
    {
      title: "",
      des: "",
      info: [
        "Minor Project: E-commerce Product Catalog. Develop an e-commerce product catalog with search functionality, using both front-end and back-end technologies.",
        "Major Project: Social Media Platform. Build a social media platform allowing users to post, like, comment, and follow other users. Implement authentication and real-time updates.",
      ],
    },
  ],
};

const fullStackCourseDes = (
  <p className="text-center mb-10 mt-8 relative z-50 mb:text-sm">
    Unveil the world of Full Stack Development through Maangler's immersive
    internship program. Tailored to fuel your passion{" "}
    <br className="lg:hidden" /> and launch your career, this program is your
    gateway to becoming a proficient <br className="lg:hidden" /> Full Stack
    Developer.
  </p>
);

export default FullstackInternship;
