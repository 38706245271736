import { ArrowDownward } from "@mui/icons-material";
import React, { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Menu } from "@mui/material";
import { Link } from "react-router-dom";
import PythonProgram from "../static/Icons/Super30/PythonProgram.png";
import JavaProgram from "../static/Icons/Super30/JavaProgram.png";
import FullStack from "../static/Icons/Super30/FullStack.png";
import Ai from "../static/Icons/Super30/AI.png";
import AppDev from "../static/Icons/Super30/AppDev.png";
import cpp from "../static/Icons/Super30/C++.png";
import Cloud from "../static/Icons/Super30/CloudComp.png";
import Cyber from "../static/Icons/Super30/cyber.png";
import Dsa from "../static/Icons/Super30/DSA.png";
import ml from "../static/Icons/Super30/ML.png";
import DataSci from "../static/Icons/Super30/DataSci.png";
import ComingSoon from "../static/Icons/Super30/coming_soon.png"

const CoursesDropdown = () => {
  return (
    <div className="px-3">
      <div className="group relative">
        <div className="flex text-gray-600 cursor-pointer">
          <p>Programs</p>
          <KeyboardArrowDownIcon />
        </div>

        <div className="rounded absolute bg-white w-[700px] border shadow-2xl opacity-0 scale-0 group-hover:opacity-100 group-hover:scale-100 transition-opacity duration-500 origin-top transform ">
          <div className="grid grid-cols-2 gap-4">
            <div className="mt-3 px-1 mb-1">
              <Link to={"/python-internship-program"}>
                <div className="flex space-x-2 hover:bg-gray-200 p-3 rounded-lg">
                  <img alt="" src={PythonProgram} className="w-12 h-12" />
                  <div>
                    <p>Python Internship Program</p>
                    <p className="text-sm font-normal">
                      Real-world experience. Limitless possibilities.
                    </p>
                  </div>
                </div>
              </Link>
              <Link to={"/java-internship-program"}>
                <div className="flex cursor-pointer space-x-2 mt-2 hover:bg-gray-200 p-3 rounded-lg">
                  <img alt="" src={JavaProgram} className=" w-12 h-12" />
                  <div>
                    <p className="">Java Internship Program</p>
                    <p className="text-sm font-normal">
                      Unlock Your Potential in the World of Java Development
                    </p>
                  </div>
                </div>
              </Link>
              <Link to={"/full-stack-program"}>
                <div className="flex cursor-pointer space-x-2 mt-2 hover:bg-gray-200 p-3 rounded-lg">
                  <img alt="" src={FullStack} className=" w-12 h-12" />
                  <div>
                    <p className="">Full Stack Internship Program</p>
                    <p className="text-sm font-normal">
                      Ignite Your Potential: Master Full Stack Java Development
                    </p>
                  </div>
                </div>
              </Link>
              <Link to={"/coming-soon"}>
                <div className="flex cursor-pointer space-x-2 mt-2 hover:bg-gray-200 p-3 rounded-lg">
                  <img alt="" src={Ai} className=" w-12 h-12" />
                  <div>
                    <p className="">Artificial Intelligence Program</p>
                    <p className="text-sm font-normal">
                      Elevate Your Skills: Dive into AI Mastery
                    </p>
                  </div>
                </div>
              </Link>
              <Link to={"/coming-soon"}>
                <div className="flex cursor-pointer space-x-2 mt-2 hover:bg-gray-200 p-3 rounded-lg">
                  <img alt="" src={ml} className=" w-12 h-12" />
                  <div>
                    <p className="">Machine Learning Program</p>
                    <p className="text-sm font-normal">
                      ML Excellence: Unleash the Power of Machine Learning
                    </p>
                  </div>
                </div>
              </Link>
              <Link to={"/coming-soon"}>
                <div className="flex cursor-pointer space-x-2 mt-2 hover:bg-gray-200 p-3 rounded-lg">
                  <img alt="" src={cpp} className=" w-12 h-12" />
                  <div>
                    <p className="">C++ Program</p>
                    <p className="text-sm font-normal">
                      C++ Mastery: Crafting Future-Ready Applications
                    </p>
                  </div>
                </div>
              </Link>
            </div>
            <div className="px-1 ">
              <Link to={"/coming-soon"}>
                <div className="flex cursor-pointer space-x-2 mt-2 hover:bg-gray-200 p-3 rounded-lg">
                  <img alt="" src={DataSci} className=" w-12 h-12" />
                  <div>
                    <p className="">Data Science Program</p>
                    <p className="text-sm font-normal">
                      Data Science Unleashed: Transform Insights into Action
                    </p>
                  </div>
                </div>
              </Link>
              <Link to={"/coming-soon"}>
                <div className="flex cursor-pointer space-x-2 mt-2 hover:bg-gray-200 p-3 rounded-lg">
                  <img alt="" src={AppDev} className=" w-12 h-12" />
                  <div>
                    <p className="">App Development Program</p>
                    <p className="text-sm font-normal">
                      App Development Revolution: Shape the Future of App
                    </p>
                  </div>
                </div>
              </Link>
              <Link to={"/coming-soon"}>
                <div className="flex cursor-pointer space-x-2 mt-2 hover:bg-gray-200 p-3 rounded-lg">
                  <img alt="" src={Cloud} className=" w-12 h-12" />
                  <div>
                    <p className="">Cloud Computing Program</p>
                    <p className="text-sm font-normal">
                      Cloud Mastery: Soar to New Heights in Computing
                    </p>
                  </div>
                </div>
              </Link>
              <Link to={"/coming-soon"}>
                <div className="flex cursor-pointer space-x-2 mt-2 hover:bg-gray-200 p-3 rounded-lg">
                  <img alt="" src={Cyber} className=" w-12 h-12" />
                  <div>
                    <p className="">Cyber Security Program</p>
                    <p className="text-sm font-normal">
                      Secure the Future: Cybersecurity Expertise Unleashed
                    </p>
                  </div>
                </div>
              </Link>
              <Link to={"/coming-soon"}>
                <div className="flex cursor-pointer space-x-2 mt-2 hover:bg-gray-200 p-3 rounded-lg">
                  <img alt="" src={Dsa} className=" w-12 h-12" />
                  <div>
                    <p className="">DSA & System Design Program</p>
                    <p className="text-sm font-normal">
                      Design Excellence: Mastering DSA and System Architecture
                    </p>
                  </div>
                </div>
              </Link>
              <Link to={"/coming-soon"}>
                <div className="flex cursor-pointer space-x-2 mt-1 hover:bg-gray-200 p-3 rounded-lg mb-2">
                  <img alt="" src={ComingSoon} className=" w-12 h-12" />
                  <div>
                    <p className="">More programs coming soon</p>
                    <p className="text-sm font-normal">
                      Stay Tuned !
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoursesDropdown;
