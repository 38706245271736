import React from "react";
import ResponsiveWrapper from "../../utils/ResponsiveWrapper";
import { LinkedIn } from "@mui/icons-material";
import Jainil from "../../static/Images/Jainil.jpg";
import Gaurav from "../../static/Images/Gaurav.jpg";
import Swaraj from "../../static/Images/Swaraj.jpg";
import Muskan from "../../static/Images/Muskan.jpg";
import Loganpriya from "../../static/Images/Logapriya.jpg";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css/skyblue";
import { Grow } from "@mui/material";
import { useInView } from "react-intersection-observer";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import smile from "../../static/smile.png";

const MentorsSections = () => {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.3 });
  return (
    <div id="mentors" className="overflow-hidden relative">
      <p
        className="text-center text-4xl lg:text-3xl sm:text-2xl font-semibold mt-36 font-sans sm:mt-28"
        ref={ref}
      >
        Meet your Mentors
      </p>
      {/* <div className="flex justify-center space-x-16 mt-6  sm:space-y-10 mb:hidden lg:grid lg:grid-cols-2 lg:space-x-0 lg:gap-4 sm:grid-cols-1">
          {Data.map((item, index) => (
            <Box
              image={item.image}
              name={item.name}
              position={item.position}
              link={item.link}
              inView={inView}
            />
          ))}
        </div> */}
      <div className="mt-10">
        {/* <Splide options={{ arrows: false, autoplay: true, rewind: true,speed:100,width:260 }} style={{}}> */}
        <Splide
          options={{
            perPage: 4,
            arrows: false,
            type: "loop",
            drag: "free",
            focus: "center",
            padding: "5rem",
            rewind: true,
            autoplay: true,
            interval: 1300,
            breakpoints: {
              1400: {
                perPage: 3,
              },
              1100: {
                perPage: 2,
              },
              800: {
                perPage: 1,
                padding: "3rem",
              },
            },
            mediaQuery: "max",
          }}
          extensions={AutoScroll}
        >
          {Data.map((item) => (
            <SplideSlide>
              <Box
                image={item.image}
                name={item.name}
                position={item.position}
                link={item.link}
                inView={inView}
              />
            </SplideSlide>
          ))}
        </Splide>
      </div>
      <img src={smile} alt="" className="absolute top-[67px]" />
    </div>
  );
};

const Box = ({ image, position, name, link, inView }) => (
  <div className="relative w-[270px] lg:justify-self-center lg:self-center sm:mx-auto  sm:mt-8 hover:shadow-xl">
    <img alt="" src={image} className="w-[270px] rounded-3xl" />
    <div className="absolute inset-0 bg-black opacity-30 rounded-3xl w-[270px]"></div>
    <a href={link} target="_blank" rel="noreferrer">
      <div
        className="absolute bottom-3 justify-between px-2 py-3 text-white  w-[250px] left-3 rounded-xl flex items-center "
        style={{
          background: "rgba( 84, 86, 87, 0.25 )",
          boxShadow: " 0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
          backdropFilter: " blur( 4px )",
          WebkitBackdropFilter: "blur(4px)",
          //-webkit-backdrop-filter: blur( 4px ),
          //borderRadius: "10px",
          //border: " 1px solid rgba( 255, 255, 255, 0.18 )",
        }}
      >
        <div className="">
          <p className="font-semibold ">{name}</p>
          <p className="text-xs mt-1">{position}</p>
        </div>

        <LinkedIn className="text-[#025f9f]" style={{ fontSize: "35px" }} />
      </div>
    </a>
  </div>
);

const Data = [
  {
    name: "Gaurav",
    position: "SWE - III @ Google",
    image: Gaurav,
    link: "https://www.linkedin.com/in/gaurav-jha-776039119/",
  },
  {
    name: "Jainil",
    position: "SDE II @Microsoft ",
    image: Jainil,
    link: "https://www.linkedin.com/in/jainilvachhani/",
  },
  {
    name: "Logapriya",
    position: "Senior Data Scientist @Amazon",
    image: Loganpriya,
    link: "https://www.linkedin.com/in/logapriya-viswanathan-546626125/",
  },

  {
    name: "Swaraj",
    position: "SDE II @ Microsoft",
    image: Swaraj,
    link: "https://www.linkedin.com/in/swaraj-software-engineer/",
  },
  {
    name: "Muskan",
    position: "SDE @Microsoft",
    image: Muskan,
    link: "https://www.linkedin.com/in/muskan-handa-8b087217a/",
  },
];
export default MentorsSections;
