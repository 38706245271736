import React, { useEffect } from "react";
import Roshan from "../../static/Courses/Roshan.jpg";
import Alka from "../../static/Courses/Alka.jpg";
import Vishal from "../../static/Courses/Vishal.jpg";
import Soumya from "../../static/Courses/Somya.jpg";
import Priti from "../../static/Courses/Priti.jpg";
import Star from "../../static/Star.png";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import clsx from "clsx";

const ProgramRatings = ({ border }) => {
  const Card = ({ data }) => {
    const { name, rating, icon } = data;
    return (
      <div
        className={` bg-[#14171e] w-[300px] flex items-center  p-3 space-x-4 rounded-xl md:w-[250px] border-[${border}] border`}
        style={{ border: `1px solid ${border}` }}
      >
        <img src={icon} alt="" className="w-[70px] h-[70px] rounded-full" />
        <div>
          <p>{name}</p>
          <div className="flex items-center space-x-2">
            <p>{rating}</p>
            <img
              src={Star}
              alt=""
              className="w-[20px] h-[19px] object-contain"
            />
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="mb-28">
      <p className="mb-10 text-3xl font-semibold text-center">
        Program Ratings
      </p>
      <Splide
        options={{
          perPage: 4,
          arrows: false,
          type: "loop",
          drag: "free",
          focus: "center",
          padding: "5rem",
          rewind: true,
          autoplay: true,
          interval: 2000,
          breakpoints: {
            1400: {
              perPage: 3,
            },
            1100: {
              perPage: 2,
            },
            800: {
              perPage: 1,
              padding: "3rem",
            },
          },
          mediaQuery: "max",
        }}
        extensions={AutoScroll}
      >
        {data.map((item) => (
          <SplideSlide>
            <Card data={item} key={item.name} />
          </SplideSlide>
        ))}
      </Splide>
    </div>
  );
};

const data = [
  { name: "Roshan Kumar", rating: "4.3", icon: Roshan },
  { name: "Alka kashyap", rating: "4.2", icon: Alka },
  { name: "Vishal Reddy", rating: "4.4", icon: Vishal },
  { name: "Soumya BU", rating: "4.1", icon: Soumya },
  { name: "Priti Singh", rating: "4.5", icon: Priti },
];
export default ProgramRatings;
