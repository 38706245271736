import React, { useState } from "react";
import RocketMan from "../../static/Images/rocketman2.png";
import super30Icon1 from "../../static/Icons/Super30/Super30Icon1.png";
import super30Icon2 from "../../static/Icons/Super30/Super30Icon2.png";
import super30Icon3 from "../../static/Icons/Super30/Super30Icon3.png";
import leftCoin from "../../static/Icons/leftCoin.png";
import rightCoin from "../../static/Icons/rightCoin.png";
import VerticalLines from "../../static/BlueVerticalLines.png";
import { Dialog, Fade } from "@mui/material";
import BookCard from "../HomePage/BookCard";
import people from "../../static/Icons/people.png";
import threeLines from "../../static/Icons/threeLines.png";

const HeroSection = () => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <>
      <Fade in={true} mountOnEnter unmountOnExit timeout={1200}>
        <div className="flex justify-between items-center px-[10%] xl:px-[3%] lg:px-[2%] md:px-[1%] relative py-20 font-sans z-20 sm:flex-col sm:px-[3%] sm:space-y-2">
          <div className="space-y-8 relative z-20">
            <p className="text-5xl font-semibold lg:text-3xl mb:text-2xl ">
              Introducing 
              <span className="text-primary relative ml-2">
                Super 30
                <img
                  src={VerticalLines}
                  alt=""
                  className="absolute -top-[45px] -right-[50px] mb:w-[60px] mb:-top-[35px] mb:-right-[35px]"
                />
              </span>
            </p>

            <p className="" style={{ fontFamily: "Manrope" }}>
              Upskilling with experts is all well & good, but having a stellar
              batch makes <br />
              your upskilling journey 10x more challenging & exciting. Meet your
              potential <br />
              batchmates & brace yourself for a brighter data-driven career.
            </p>
            <button
              className="bg-primary text-white font-semibold px-14 py-3 rounded-[30px] hover:bg-[#0288b0] sm:px-10 sm:py-2"
              onClick={() => setOpenModal(true)}
            >
              Register Now
            </button>
          </div>
          <div>
            <img src={RocketMan} alt="" className="w-[320px] relative z-20" />
          </div>
          <img
            alt=""
            src={super30Icon1}
            className="absolute z-10"
            style={{ top: "-16%", right: "60%" }}
          />
          <img
            alt=""
            src={super30Icon2}
            className="absolute z-10"
            style={{ top: "-20%", right: "23%" }}
          />
          <img
            alt=""
            src={super30Icon3}
            className="absolute z-10"
            style={{ bottom: "0%", right: "40%" }}
          />
          <img
            alt=""
            src={leftCoin}
            className="absolute z-10 sm:hidden"
            style={{ bottom: "46%", left: "0%" }}
          />
          <img
            alt=""
            src={rightCoin}
            className="absolute z-10 sm:hidden"
            style={{ bottom: "4%", right: "0%" }}
          />
        </div>
      </Fade>
      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <BookCard
          people={people}
          threeLines={threeLines}
          onClose={() => setOpenModal(false)}
        />
      </Dialog>
    </>
  );
};

export default HeroSection;
