import React, { useState } from "react";
import rightVerticalLines from "../../static/Python/blueVerticalLines.png";
import Hat from "../../static/Python/Hat.png";
import Medal from "../../static/Python/Medall.png";
import Rocket from "../../static/Python/Rocket.png";
import { Dialog } from "@mui/material";
import BookCard from "../HomePage/BookCard";
import people from "../../static/Icons/people.png";
import threeLines from "../../static/Icons/threeLines.png";
import { isLoggedIn } from "../../utils/helperFunctions";
import axios from 'axios';
import Cookies from "js-cookie";
import { toast } from 'react-toastify';


const CourseInfo = ({ data, border,courseName }) => {
  const [openModal, setOpenModal] = useState(false); //modal state
  const handleClick= async ()=>{
    const authorized = isLoggedIn();

    const api_URL = `https://maangler-backend-production.up.railway.app/payment/${courseName}`;
    //const authToken= "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY0ZGY0NWUwMWE3MWYzNzMzNTVhZGEwOCIsImVtYWlsIjoidXNlckBleGFtcGxlLmNvbSIsImlhdCI6MTcwMjI5NjYyNywiZXhwIjoxNzAyOTAxNDI3fQ._E6-g0cIY6wl7gp8Jm6Vvu2Un2_KDupIi4WEp5T2UEc"; // local 
    const authToken = Cookies.get("access_token"); // Prod 
    if(authorized){
      await axios.get(api_URL, {
        headers:{
          'Authorization': `Bearer ${authToken}`
        }
      })
      .then(response =>{
        console.log(response.data.paymentUrl)
        window.location.href=response.data.paymentUrl;
        
      })
      .catch(error => {
        console.error('Error:', error);
        console.log(error)
        // Handle errors appropriately in your application
      });
    } else{
      //setOpenModal(true)
      toast.warn('Please log in to make a payment.', {position: "bottom-left", });
    }
    
  };
  return (
    <div className="flex flex-col items-center space-y-16">
      <div>
        <p className="text-center text-2xl font-semibold mb-6">Month 1</p>
        <div className="flex space-x-4 xl:grid xl:grid-cols-2 xl:space-x-0 xl:gap-16 sm:gap-2 sm:grid-cols-1">
          {data["Month 1"].map((item, index) =>
            index !== data["Month 1"].length - 1 ? (
              <Card key={index} item={item} border={border} />
            ) : (
              <div className="relative">
                <img
                  alt="Hat"
                  src={Rocket}
                  className="absolute -top-[40px] -right-[20px] h-[84px] w-[64px] object-contain z-10"
                />
                <Card key={index} item={item} border={border} />
              </div>
            )
          )}
        </div>
      </div>
      <div>
        <p className="text-center text-2xl font-semibold mb-6">Month 2</p>
        <div className="flex space-x-4 xl:grid xl:grid-cols-2 xl:space-x-0 xl:gap-16 sm:gap-2 sm:grid-cols-1">
          {data["Month 2"].map((item, index) =>
            index !== data["Month 2"].length - 1 ? (
              <Card key={index} item={item} border={border} />
            ) : (
              <div className="relative">
                <img
                  alt="Medal"
                  src={Medal}
                  className="absolute -top-[30px] -right-[20px] h-[54px] w-[54px] object-contain z-10"
                />
                <Card key={index} item={item} border={border} />
              </div>
            )
          )}
        </div>
      </div>
      {data?.["Month 3"] && (
        <div>
          <p className="text-center text-2xl font-semibold mb-6 ">Month 3</p>
          <div className="flex space-x-4 xl:grid xl:grid-cols-2 xl:space-x-0 xl:gap-16 sm:gap-2 sm:grid-cols-1">
            {/* {data["Month 3"].map((item, index) => (
            <div className="relative">
              <img
                alt="Hat"
                src={Hat}
                className="absolute -top-[36px] -right-[50px] h-[64px] w-[110px] object-contain z-10"
              />
              <Card key={index} item={item} border={border} />
            </div>
          ))} */}
            {data["Month 3"].map((item, index) =>
              index !== data["Month 1"].length - 1 ? (
                <Card key={index} item={item} border={border} />
              ) : (
                <div className="relative">
                  <img
                    alt="Hat"
                    src={Hat}
                    className="absolute -top-[40px] -right-[20px] h-[84px] w-[64px] object-contain z-10"
                  />
                  <Card key={index} item={item} border={border} />
                </div>
              )
            )}
          </div>
        </div>
      )}
      <div className="flex items-center">
        <button
          onClick= {handleClick}
          className="border-2 px-16 py-2 border-white  rounded-3xl  mb:px-10 transition ease-in-out duration-500 hover:scale-105"
        >
          Join Now
        </button>
        <img src={rightVerticalLines} alt="" className="w-[50px] mb-10" />
      </div>
      {/* 
      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <BookCard
          people={people}
          threeLines={threeLines}
          onClose={() => setOpenModal(false)}
        />
      </Dialog>
      */}
      
      
    </div>
  );
};
const Card = ({ item, border }) => {
  const { title, des, info } = item;
  return (
    <div
      className={
        "space-y-1 border px-8 py-4 w-[290px] rounded-xl sm:my-2 mb:min-h-[280px] transition ease-in-out duration-500 cursor-pointer hover:scale-105 min-h-[280px] "
      }
      style={{ border: `1px solid ${border}`, maxWidth: "290px" }}
    >
      <p className="text-center text-lg font-semibold">{title}</p>
      <p
        className="text-[#076AFF] text-center font-semibold"
        style={{ color: `${border}` }}
      >
        {des}
      </p>
      <ul className="list-disc space-y-1 text-sm">
        {info.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </div>
  );
};

export default CourseInfo;
