import React, { useEffect, useState } from "react";
import NavBar from "./global/NavBar.jsx";
import Footer from "./global/Footer.jsx";
import { HomePage } from "./pages/HomePage";
import AboutUs from "./pages/AboutUs";
import Super30 from "./pages/Super30";
import { Navigate, Route, Routes } from "react-router-dom";
import SignInSignUp from "./pages/SignInSignUp";
import PythonInternship from "./pages/PythonInternship";
import JavaInternship from "./pages/JavaInternship";
import CampusAmbassador from "./pages/CampusAmbassador";
import CollegeReachOutPage from "./pages/CollegeReachOutPage";
import HiringPage from "./pages/HiringPage";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { selectIsLoggedIn, setLoggedIn } from "./redux/auth/authSlice";
import { isLoggedIn } from "./utils/helperFunctions";
import LoggedInRoute from "./utils/LoggedInRoute";
import Profile from "./global/Profile.jsx";
import ProfilePage from "./pages/ProfilePage.jsx";
import TermsOfUse from "./pages/TermsOfUse.jsx";
import PrivacyPolicy from "./pages/PrivacyPolicy.jsx";
import { setUserData } from "./redux/profile/profileSlice.js";
import { getProfileData } from "./redux/auth/apiSlice.js";
import FullstackInternship from "./pages/FullstackInternship.jsx";
import BecomeMentor from "./pages/BecomeMentor.jsx";
import ComingSoon from "./pages/ComingSoon.jsx";
import Success from "./pages/Success.jsx";
import Failure from "./pages/Failure.jsx";
import Resume from "./pages/Resume.jsx";

const App = () => {
  const authorized = isLoggedIn();
  const [isLoading, setIsLoading] = useState(true);
  const isAuthorized = useSelector(selectIsLoggedIn);
  const dispatch = useDispatch();
  const access_token = Cookies.get("access_token");

  useEffect(() => {
    if (access_token) {
      const isTokenExpired = new Date(access_token.expiry) < new Date();
      //when access token is expired
      if (isTokenExpired) {
        console.log("Token expired .....");
      } else {
        console.log("Token not expired .....");
        setIsLoading(false);
        dispatch(setLoggedIn({ loggedInState: true, token: access_token }));
        dispatch(getProfileData());
      }
    }
    //when there is no access token
    else {
      console.log("Token not available.....");
      setIsLoading(false);
      dispatch(setLoggedIn({ loggedInState: false, token: null }));
    }
  }, [isAuthorized]);
  return isLoading ? null : (
    <div className=" bg-[#fcfcfc] flex flex-col overflow-auto font-sans h-[100vh] min-h-[100vh] relative">
      <NavBar />
      <div className="grow">
        <Routes>
          <Route element={<HomePage />} path="/" />
          <Route element={<AboutUs />} path="/about-us" />
          <Route element={<Super30 />} path="/super-30" />
          <Route
            element={
              <LoggedInRoute>
                <SignInSignUp />
              </LoggedInRoute>
            }
            path="/account/:type"
          />
          <Route
            element={<PythonInternship />}
            path="/python-internship-program"
          />
          <Route element={<JavaInternship />} path="/java-internship-program" />
          <Route element={<FullstackInternship />} path="/full-stack-program" />
          <Route element={<CampusAmbassador />} path="/campus-ambassador" />
          <Route element={<CollegeReachOutPage />} path="/college-reach-out" />
          <Route element={<HiringPage />} path="/hiring" />
          <Route element={<ProfilePage />} path="/profile" />
          <Route element={<TermsOfUse />} path="/terms-of-use" />
          <Route element={<PrivacyPolicy />} path="/privacy-policy" />
          <Route element={<BecomeMentor />} path="/become-mentor"/>
          <Route element={<ComingSoon />} path="/coming-soon"/>
          <Route element={<Success/>} path="/payment-success"/>
          <Route element={<Failure/>} path="/payment-failure"/>
          <Route element={<Resume/>} path="/resume-builder"/>
        </Routes>
      </div>
      <Footer />
    </div>
  );
};

export default App;
