import React from "react";
import HeroSection from "../components/Courses/HeroSection";
import ProgramRatings from "../components/PythonInternshipProgram/ProgramRatings";
import CourseInfo from "../components/Courses/CourseInfo";
//import MentorsStrip from "../components/PythonInternshipProgram/MentorsStrip";
import MentorsSections from "../components/HomePage/MentorsSections";
import Background from "../static/Java/Background.png";
import MentorsStrip from "../components/PythonInternshipProgram/MentorsStrip";

const JavaInternship = () => {
  return (
    <div
      style={{
        backgroundImage: `url(${Background})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      className="text-white pb-20 overflow-hidden"
    >
      {/* <HeroSection /> */}
      <HeroSection
        courseDescription={JavaCourseDes}
        title={"Java Internship Program"}
      />
      <ProgramRatings border={"#1BFF07"} />
      <CourseInfo
       data={data}
       border={"#1BFF07"}
       courseName="Java"
       />
      <MentorsStrip />
      <MentorsSections />
    </div>
  );
};

const JavaCourseDes = (
  <p className="text-center mb-10 mt-8 relative z-50 mb:text-sm">
    Maangler JAVA is a meticulously crafted program to kick-start your journey
    into the world of JAVA & Data Science. Our rigorous curriculum, led by
    industry veterans,
    <br className="lg:hidden" /> mentors you as you progress towards growth,
    ultimately making you an adept #maangler. With our unwavering support,
    <br className="lg:hidden" /> you can drive change not only in your career
    but also in the realm of Data Science.
  </p>
);

const data = {
  "Month 1": [
    {
      title: "Week 1",
      des: "Introduction to Java",
      info: [
        "What is Java?",
        "Setting up Java Development Environment",
        "Why do we need to learn Python?",
        "Variables and data types",
        "Input and output in Java",
      ],
    },
    {
      title: "Week 2",
      des: "Control Statements",
      info: ["Conditional statements", "Loops", "Switch statements"],
    },
    {
      title: "Week 3",
      des: "Arrays & Methods",
      info: [
        "Array basics",
        "Array manipulation",
        "Writing methods",
        "Passing parameters",
      ],
    },
    {
      title: "Week 4",
      des: "Object-Oriented Programming",
      info: [
        "Introduction to OOP",
        "Classes and objects",
        "Constructors and methods",
        "Inheritance",
      ],
    },
  ],
  "Month 2": [
    {
      title: "Week 1",
      des: "Interfaces & Polymorphism",
      info: ["Interfaces", "Implementing interfaces", "Polymorphism"],
    },
    {
      title: "Week 2",
      des: "Exception Handling",
      info: [
        "Handling exceptions",
        "Try-catch block",
        "Creating and writing to files",
        "Reading from files",
      ],
    },
    {
      title: "Week 3",
      des: "Collections Framework",
      info: [
        "ArrayList, LinkedList, and other collection classes",
        "Iterating over collections",
        "Sorting and searching",
      ],
    },
    {
      title: "Week 4",
      des: "Multithreading & Networking",
      info: [
        "Creating threads",
        "Synchronizing threads",
        "Network programming basics",
      ],
    },
  ],
  "Month 3": [
    {
      title: "Week 1",
      des: "Capstone Projects",
      info: [
        "First 10 Days Minor Projects.",
        "Next 20 Days Major Projects.",
        "Weekly Webinars and insights from MAANG Mentors.",
        "Community Events , group discussions and many more.",
      ],
    },
  ],
};

export default JavaInternship;
